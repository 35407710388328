p {
  margin: 0;
  line-height: 1.5em;
}

h1, h2, h3 {
  line-height: 1.25em;
  margin: 0;
}

h1 {
  font-size: 1.65em;
  color: #fffff6;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.25em;
}

.gradient-container {
  background: linear-gradient(29deg, #3d5695, #ca9a83);
  height: 100vh;
}

.hero {
  padding-top: 60px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  font-size: 22px;
  padding: 1.5rem;
}

.hero h1 {
  margin-top: 5rem;
}

.hero span {
  display: block;
}

main {
  color: #595959;
}

.App {
  height: 100%;
}

.container {
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none;
}

.section-header {
  margin-top: 20px;
  font-size: 24px;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: #fffff6;
}

a:visited {
  color: #fffff6;
}

ul {
  display: inline-block;
  list-style: none;
}

main > a:visited {
  color: #fffff6;
}

.links {
  display: none;
}

li {
  display: inline-block;
}

.image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

@media screen and (min-width: 600px) {
  body {
    font-size: 18px;
  }

  #bio {
    padding: 1.5em 1em;
    font-size: 20px;
  }

  .section-header {
    font-size: 26px;
  }
}

@media screen and (min-width: 768px) {
  .hero {
    font-size: 28px;
  }

  #bio {
    padding: 1.5em 4em;
    font-size: 22px;
  }

  .project {
    padding: 20px;
  }

  .section-header {
    font-size: 30px;
  }
}

@media screen and (min-width: 1200px) {
  body {
    font-size: 20px;
  }

  .navbar {
    padding: 0 3rem;
  }

  .hero {
    padding: 0 3rem;
  }

  .section-header {
    font-size: 32px;
  }

  #portfolio {
    padding: 0 3rem 3rem 3rem;
  }

  #bio {
    padding: 2em 10em;
    font-size: 24px;
  }
  
  .project {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .project h2 {
    font-size: 32px;
  }

  .project h3 {
    font-size: 25px;
  }

  .project p {
    font-size: 20px;
  }

  .image {
    display: block;
    flex-basis: 0%;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    margin-bottom: 0;
  }

  .image > img {
    min-width: 100%;
  }

  .hero {
    font-size: 35px;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-basis: 400px;
    flex-grow: 0;
    flex-shrink: 0;
    padding-left: 60px;
    margin-bottom: 0;
  }
}