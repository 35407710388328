#bio {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#bio > p {
  text-align: left;
  padding: 1.5em;
  color: #595959;
}