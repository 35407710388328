.project {
  margin: 20px 0;
  background-color: #f8f8f8;
  padding: 20px 5px;
}

.image {
  border-radius: 4px;
  margin-bottom: 20px;
}

.content {
  text-align: left;
}

.content h2 {
  font-size: 26px;
  margin-bottom: 3px;
}

.content h3 {
  font-size: 22px;
}

.content p, .content a {
  font-size: 18px;
}

.content p {
  color: #595959;
  padding-bottom: 20px;
}

.project-link-container {
  display: block;
}

.project-screenshot {
  box-shadow: 0px 0px 10px rgb(0, 0, 0, 0.5);
}

#portfolio {
  padding: 1.5em;
}

#portfolio a {
  color: #595959;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

#portfolio a:hover, #portfolio a:active {
  color: #ca9a83;
}

#portfolio hr {
  outline: none;
  border: none;
  border-top: 3px solid #595959;
  margin: 1em 0;
}