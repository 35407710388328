.side-drawer {
  height: 100%;
  background: #373633;
  color: #fffff6;
  box-shadow: 1px 0px 7px rgba(0,0,0,0.5);
  position: fixed;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.close-icon {
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1.5rem; 
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin-top: 60px; */
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  font-size: 25px;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #ca9a83;
}

@media (min-width: 769px) {
  .side-drawer {
    display: none;
  }
}

@media (min-width: 500px) {
  .side-drawer a {
    font-size: 32px;
  }
}