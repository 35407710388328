footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1.5em;
  margin: 0;
  color: #fffff6;
  background-color: #595959;
  height: 20vh;
}

footer h2 {
  font-size: 22px;
  margin: 0 0 5px 0;
}

.link-container {
  margin-top: 5px;
}

footer a {
  font-size: 18px;
  color: #fffff6;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

footer a:hover,
footer a:active {
  color: #ca9a83;
}

footer a:nth-child(1) {
  margin-right: 20px;
}

footer a:nth-child(3) {
  margin-left: 20px;
}

@media screen and (min-width: 1200px) {
  footer a {
    font-size: 22px;
  }

  footer h2 {
    font-size: 30px;
  }
}